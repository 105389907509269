
import React from 'react';
import store from './App/Redux/Store/Store';
import Main from './Main';
import { Provider } from 'react-redux';

export default function App() {
 
  return (
    <Provider store={store}>
<Main/>
 
 </Provider>
  );
}
