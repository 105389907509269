

import React, { useState } from 'react';
import { Badge, BarChart2, ChevronLeft, ChevronRight, Heart, MessageCircle, MoreHorizontal, Repeat, Share2, Smile, Star, UserPlus } from 'lucide-react';
import { Eye, Film, Send } from 'react-feather';
import { Image, } from 'lucide-react';
import { MapPin, Search, Clock, SlidersHorizontal, Save, SaveIcon, } from 'lucide-react';
// 

const Company = () => {
    const jobs1 = [
        {
            company: "MetaMask",
            logo: "https://cdn.pixabay.com/photo/2021/12/14/22/29/meta-6871457_1280.png",
            position: "Product Designer",
            applicants: "25 Applicants",
            tags: ["Entry Level", "Full-Time"],
            description: "Doing the right thing for investors is what we're all about at Vanguard, and that includes...",
            salary: "$250/hr",
            postedTime: "Posted 2 days ago"


        },
        {
            company: "Netflix",
            logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXeMySNjwvvK61yM2AvfboJC6nZPSYebVjyw&s",
            position: "Sr. UX Designer",
            applicants: "14 Applicants",
            tags: ["Expert", "Part-Time", "Remote"],
            description: "Netflix is one of the world's leading streaming entertainment services...",
            salary: "$195/hr",
            postedTime: "Posted 1 days ago"


        },

        {
            company: "Google",
            logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTaa2hwiG4o2vVy3yYZKTBDlqkTuQ0n3KEL2w&s",
            position: "Software Engineer",
            applicants: "30 Applicants",
            tags: ["Expert", "Full-Time"],
            description: "Join our innovative team at Google to work on exciting projects that impact millions...",
            salary: "$220/hr",
            postedTime: "Posted 3 days ago"


        },
        {
            company: "Apple",
            logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSaURCDEX-d8qAHRsaLPq92BV0UFx8nDYnWgg&s",
            position: "UI/UX Designer",
            applicants: "22 Applicants",
            tags: ["Intermediate", "Full-Time", "Remote"],
            description: "Design intuitive and engaging user interfaces for Apple products and services...",
            salary: "$200/hr",
            postedTime: "Posted 4 days ago"


        },
        {
            company: "Amazon",
            logo: "https://cdn0.iconfinder.com/data/icons/most-usable-logos/120/Amazon-512.png",
            position: "Data Scientist",
            applicants: "15 Applicants",
            tags: ["Intermediate", "Full-Time"],
            description: "Analyze data and provide actionable insights to drive business decisions at Amazon...",
            salary: "$180/hr",
            postedTime: "Posted 8 days ago"


        },
        {
            company: "Tesla",
            logo: "https://w7.pngwing.com/pngs/628/405/png-transparent-tesla-logo-icon.png",
            position: "Mechanical Engineer",
            applicants: "12 Applicants",
            tags: ["Intermediate", "Full-Time"],
            description: "Work on cutting-edge electric vehicles and battery technology at Tesla...",
            salary: "$195/hr",
            postedTime: "Posted 9 days ago"


        },
        {
            company: "Adobe",
            logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ83crHmsZOWIxClObXAFYYr1CjJZBBk8e0hw&s",
            position: "Creative Cloud Engineer",
            applicants: "10 Applicants",
            tags: ["Expert", "Full-Time"],
            description: "Join the Adobe team to build innovative tools for creatives worldwide...",
            salary: "$210/hr",
            postedTime: "Posted 10 days ago"


        },
        {
            company: "Slack",
            logo: "https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/306_Slack_logo-512.png",
            position: "Product Manager",
            applicants: "8 Applicants",
            tags: ["Expert", "Full-Time"],
            description: "Lead the product strategy and execution for Slack's next-generation features...",
            salary: "$230/hr",
            postedTime: "Posted 12 days ago"


        },
    ];
    const events =
        [
            {
                "date": "Tue, Jan 17, 2023, 6:00 PM",
                "title": "Introduction to Machine Learning Concepts",
                "instructor": "Ms. Anjali Verma",
                "role": "Machine Learning Engineer at Google",
                "attendees": 20,
                "image": "https://images.pexels.com/photos/256502/pexels-photo-256502.jpeg?auto=compress&cs=tinysrgb&w=600"
            },
            {
                "date": "Thu, Feb 2, 2023, 5:00 PM",
                "title": "Data Visualization with Tableau for Beginners",
                "instructor": "Mr. Ravi Kapoor",
                "role": "Data Analyst at Deloitte",
                "attendees": 18,
                "image": "https://images.pexels.com/photos/3861969/pexels-photo-3861969.jpeg?auto=compress&cs=tinysrgb&w=600"
            },
            {
                "date": "Mon, Mar 13, 2023, 4:30 PM",
                "title": "Getting Started with Python for Data Science",
                "instructor": "Dr. Meghna Singh",
                "role": "Lead Data Scientist at IBM",
                "attendees": 25,
                "image": "https://images.pexels.com/photos/1181671/pexels-photo-1181671.jpeg?auto=compress&cs=tinysrgb&w=600"
            },


        ];

    const jobs = [
        {
            title: "Software Engineer",
            location: "Hyderabad"
        },
        {
            title: "Marketing Manager",
            location: "Chennai"
        },
        {
            title: "UI/UX Designer",
            location: "Pune"
        },
        {
            title: "Data Analyst",
            location: "Mumbai"
        },
        {
            title: "Sales Executive",
            location: "Delhi"
        },
        {
            title: "HR Coordinator",
            location: "Noida"
        },


    ];

    const courses = [
        {
            id: 1,
            title: 'React Development by Upskill',
            image: 'https://images.pexels.com/photos/1181675/pexels-photo-1181675.jpeg',
            rating: 4.5,
            enrolled: 120,
            cost: '$199',
            company: 'Scaler',
            hours: '30 Hours',
        },
        {
            id: 2,
            title: 'Cybersecurity Fundamentals by Udemy',
            image: 'https://images.pexels.com/photos/3949100/pexels-photo-3949100.jpeg?auto=compress&cs=tinysrgb&w=600',
            rating: 4.2,
            enrolled: 95,
            cost: '$149',
            company: 'Udemy',
            hours: '25 Hours',
        },
        {
            id: 3,
            title: 'Full Stack Web Dev Bootcamp',
            image: 'https://images.pexels.com/photos/3184294/pexels-photo-3184294.jpeg',
            rating: 4.8,
            enrolled: 200,
            cost: '$299',
            company: 'Coursera',
            hours: '40 Hours',
        },
        {
            id: 4,
            title: 'Blockchain and Cryptocurrency Essentials',
            image: 'https://images.pexels.com/photos/5980856/pexels-photo-5980856.jpeg?auto=compress&cs=tinysrgb&w=400',
            rating: 4.6,
            enrolled: 150,
            cost: '$249',
            company: 'edX',
            hours: '35 Hours',
        },
        {
            id: 5,
            title: 'UI Vector Illustration Design',
            instructor: 'Savannah Nguyen',
            avatar: 'https://images.pexels.com/photos/1824273/pexels-photo-1824273.jpeg?auto=compress&cs=tinysrgb&w=400',
            image: 'https://images.pexels.com/photos/6289025/pexels-photo-6289025.jpeg?auto=compress&cs=tinysrgb&w=400',
            rating: 4.5,
            enrolled: 120,
            cost: '$199',
            completed: 45,
            daysLeft: 3,
            duration: 30,
        },
        {
            id: 6,
            title: 'UI Styleguide with Figma',
            instructor: 'Orchestra',
            avatar: 'https://images.pexels.com/photos/3581695/pexels-photo-3581695.jpeg?auto=compress&cs=tinysrgb&w=400',
            image: 'https://images.pexels.com/photos/3471423/pexels-photo-3471423.jpeg?auto=compress&cs=tinysrgb&w=400',
            rating: 4.8,
            enrolled: 124,
            cost: '$149',
            completed: 75,
            daysLeft: 5,
            duration: 40,
        },
        {
            id: 7,
            title: 'Marketing Analytics Essentials',
            instructor: 'Lara McKenzie',
            avatar: 'https://images.pexels.com/photos/1212984/pexels-photo-1212984.jpeg?auto=compress&cs=tinysrgb&w=400',
            image: 'https://images.pexels.com/photos/7109162/pexels-photo-7109162.jpeg?auto=compress&cs=tinysrgb&w=400',
            rating: 4.7,
            enrolled: 230,
            cost: '$199',
            completed: 80,
            daysLeft: 2,
            duration: 30,
        },
        {
            id: 8,
            title: 'Creative Writing: Fiction Fundamentals',
            instructor: 'J.K. Simmons',
            avatar: 'https://images.pexels.com/photos/1319911/pexels-photo-1319911.jpeg?auto=compress&cs=tinysrgb&w=400',
            image: 'https://images.pexels.com/photos/210661/pexels-photo-210661.jpeg?auto=compress&cs=tinysrgb&w=400',
            rating: 4.9,
            enrolled: 150,
            cost: '$99',
            completed: 60,
            daysLeft: 10,
            duration: 50,
        }

    ];
    const companyData = {
        name: "CollegeMate",
        description: " CollegmateCommunity is a platform designed to bridge the gap between students from all over the world. It offers a space for students to exchange knowledge, ideas, and experiences, fostering a collaborative environment. By joining the community, students can access valuable resources, mentorship, and networking opportunities that enhance their personal and academic growth. Whether you're looking to improve your skills, get advice, or connect with like-minded individuals, CollegmateCommunity empowers you to thrive in your educational journey",
        website: "https://community.collegemate.io/",
        phone: "+91-8105827558",

        industry: "E-Learning Providers",
        employees: "501-1,000 employees",
        members: "1,320 associated members",
        headquarters: "Bangalore, Karnataka",

    };
    const posts = [
        {
            authorInfo: {
                name: "Ali Husni",
                profilePicture: "https://images.pexels.com/photos/6115039/pexels-photo-6115039.jpeg?auto=compress&cs=tinysrgb&w=400",
                timeAgo: "25 minutes ago",
                isFollowing: false
            },
            content: {
                postText: `🎓 Exciting News! Our college is offering a new course in Artificial Intelligence starting next semester! 
                            Don't miss the chance to learn from industry experts and boost your career. Enroll now!`,
                image: "https://images.pexels.com/photos/256542/pexels-photo-256542.jpeg?auto=compress&cs=tinysrgb&w=400",
                imageAlt: "AI Course Announcement"
            },
            engagementStats: {
                likes: "250 likes",
                comments: "45 Comment",
                shares: "30x Share",
                views: "1.2k Views"
            },
            actions: {
                followText: "Follow",
                likeIcon: "Heart",
                commentIcon: "MessageCircle",
                shareIcon: "Share2",
                viewIcon: "Eye"
            }
        },
        {
            authorInfo: {
                name: "Sara Ahmed",
                profilePicture: "https://images.pexels.com/photos/6189629/pexels-photo-6189629.jpeg?auto=compress&cs=tinysrgb&w=400",
                timeAgo: "1 hour ago",
                isFollowing: false
            },
            content: {
                postText: `📅 Mark Your Calendars! Join us for the Annual Career Fair this Friday! 
                            Network with top companies, discover internships, and find job opportunities!`,
                image: "https://images.pexels.com/photos/1438072/pexels-photo-1438072.jpeg?auto=compress&cs=tinysrgb&w=400",
                imageAlt: "Career Fair Event"
            },
            engagementStats: {
                likes: "400 likes",
                comments: "60 Comment",
                shares: "50x Share",
                views: "3.5k Views"
            },
            actions: {
                followText: "Follow",
                likeIcon: "Heart",
                commentIcon: "MessageCircle",
                shareIcon: "Share2",
                viewIcon: "Eye"
            }
        },
        {
            authorInfo: {
                name: "John Doe",
                profilePicture: "https://images.pexels.com/photos/3765130/pexels-photo-3765130.jpeg?auto=compress&cs=tinysrgb&w=400",
                timeAgo: "2 hours ago",
                isFollowing: true
            },
            content: {
                postText: `🎉 Join us for a Workshop on Resume Building next week! 
                            Learn tips and tricks to create an impressive resume and land your dream job!`,
                image: "https://images.pexels.com/photos/5905774/pexels-photo-5905774.jpeg?auto=compress&cs=tinysrgb&w=400",
                imageAlt: "Resume Building Workshop"
            },
            engagementStats: {
                likes: "320 likes",
                comments: "25 Comment",
                shares: "15x Share",
                views: "2.1k Views"
            },
            actions: {
                followText: "Follow",
                likeIcon: "Heart",
                commentIcon: "MessageCircle",
                shareIcon: "Share2",
                viewIcon: "Eye"
            }
        },
        {
            authorInfo: {
                name: "Emily Johnson",
                profilePicture: "https://images.pexels.com/photos/6695047/pexels-photo-6695047.jpeg?auto=compress&cs=tinysrgb&w=400",
                timeAgo: "3 hours ago",
                isFollowing: false
            },
            content: {
                postText: `📚 Don't forget to register for our Summer Internship Program! 
                            Gain valuable experience and build your professional network while studying!`,
                image: "https://images.pexels.com/photos/3184306/pexels-photo-3184306.jpeg?auto=compress&cs=tinysrgb&w=400",
                imageAlt: "Summer Internship Program"
            },
            engagementStats: {
                likes: "180 likes",
                comments: "12 Comment",
                shares: "20x Share",
                views: "850 Views"
            },
            actions: {
                followText: "Follow",
                likeIcon: "Heart",
                commentIcon: "MessageCircle",
                shareIcon: "Share2",
                viewIcon: "Eye"
            }
        },
        {
            authorInfo: {
                name: "Michael Brown",
                profilePicture: "https://images.pexels.com/photos/7879360/pexels-photo-7879360.jpeg?auto=compress&cs=tinysrgb&w=400",
                timeAgo: "4 hours ago",
                isFollowing: false
            },
            content: {
                postText: `🌟 Excited to announce our Guest Lecture series featuring industry leaders! 
                            First session is on Digital Marketing - don't miss out on this opportunity to learn!`,
                image: "https://images.pexels.com/photos/3184190/pexels-photo-3184190.jpeg?auto=compress&cs=tinysrgb&w=400",
                imageAlt: "Guest Lecture Series"
            },
            engagementStats: {
                likes: "560 likes",
                comments: "80 Comment",
                shares: "40x Share",
                views: "4.8k Views"
            },
            actions: {
                followText: "Follow",
                likeIcon: "Heart",
                commentIcon: "MessageCircle",
                shareIcon: "Share2",
                viewIcon: "Eye"
            }
        }
    ];
    const categories = [
        { name: 'All', active: true },
        { name: 'Images ', active: false },
        { name: 'Vedios', active: false },
        { name: 'Documents', active: false },
        { name: 'Articles', active: false },
    ];
    const reviewsData = {
        period: "March 2021 - February 2022",
        totalReviews: "5.0k",
        growth: "61%",
        averageRating: 4.0,
        ratingDistribution: [
            { stars: 5, count: "2.0k" },
            { stars: 4, count: "1.0k" },
            { stars: 3, count: "500" },
            { stars: 2, count: "200" },
            { stars: 1, count: "0" },
        ],
        reviews: [
            {
                id: 1,
                name: "Alex Johnson",
                avatar: "https://images.pexels.com/photos/874158/pexels-photo-874158.jpeg?auto=compress&cs=tinysrgb&w=600",
                totalSpend: "$0", // Assume it's a free or subscription-based app
                totalReviews: 8,
                rating: 5,
                date: "05-11-2024",
                comment: "CollegeMate has been a lifesaver! It helps me keep track of all my assignments and deadlines in one place. The study group feature made it so easy to connect with classmates and organize study sessions. Highly recommend it to any college student!"
            },
            {
                id: 2,
                name: "Megan Lee",
                avatar: "https://images.pexels.com/photos/1121796/pexels-photo-1121796.jpeg?auto=compress&cs=tinysrgb&w=600",
                totalSpend: "$0",
                totalReviews: 12,
                rating: 4,
                date: "02-11-2024",
                comment: "I've been using CollegeMate for a few months now, and it’s fantastic. The calendar and reminders ensure I never miss a class or assignment. The only thing I wish it had was a notes section to keep everything in one app, but otherwise, it’s great!"
            },
            {
                id: 3,
                name: "Chris Thompson",
                avatar: "https://images.pexels.com/photos/91227/pexels-photo-91227.jpeg?auto=compress&cs=tinysrgb&w=600",
                totalSpend: "$0",
                totalReviews: 5,
                rating: 4,
                date: "29-10-2024",
                comment: "A must-have app for college students! CollegeMate makes managing my schedule and keeping track of assignments so easy. The only downside is that it could use more customization options, but it’s still very user-friendly."
            },
            {
                id: 4,
                name: "Taylor Brown",
                avatar: "https://images.pexels.com/photos/845434/pexels-photo-845434.jpeg?auto=compress&cs=tinysrgb&w=600",
                totalSpend: "$0",
                totalReviews: 20,
                rating: 5,
                date: "20-10-2024",
                comment: "CollegeMate is awesome! I love the social features that let me connect with classmates for group projects. It’s intuitive and reliable, making it perfect for managing my college life efficiently. Worth every bit of attention!"
            }
        ]
    };

    const StarRating = ({ rating }) => (
        <div className="flex space-x-1">
            {[1, 2, 3, 4, 5].map((star) => (
                <Star
                    key={star}
                    className={`w-4 h-4 ${star <= rating ? 'text-yellow-400 fill-yellow-400' : 'text-gray-300'}`}
                />
            ))}
        </div>
    );
    const [activeSection, setActiveSection] = useState("home");

    const handleClick = (section) => {
        setActiveSection(section);
    };

    return (
        <>
            <div className="mb-6">

                <div className="flex items-center text-sm text-gray-500 ">
                    <span className="hover:text-blue-600 cursor-pointer">Home</span>
                    <span className="mx-2">›</span>
                    <span className=' text-purple-600'>Company Profile</span>
                </div>
            </div>
            <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                <div className="relative h-48 bg-purple-400">
                    <img
                        src="/assets/images/collegemate.png"
                        alt="Collegemate Cover"
                        className="w-full h-full object-cover opacity-70"
                    />

                </div>
                <div className="px-6 py-8 bg-white dark:bg-slate-900 text-gray-800 dark:text-gray-100">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-4">
                            <div className="relative -mt-16 w-32 h-full  border-2 border-white bg-white overflow-hidden">
                                <img
                                    src="/assets/images/cp.png"
                                    alt="Collegemate Logo"
                                    className="w-full h-full object-contain"
                                />
                            </div>
                            <div>
                                <h2 className="text-3xl font-bold text-gray-800 dark:text-gray-100 text-left">COLLEGEMATE</h2>

                                <p className="text-gray-900 dark:text-gray-600 text-left">Unlock Your Potential with Smart Learning
                                    #LearnBetterAchieveMore</p>
                                <p className="text-gray-600 dark:text-gray-700 text-sm text-left">E-Learning Providers Bangalore, Karnataka 105K followers 501-1K employees</p>
                            </div>
                        </div>

                    </div>
                    <div className="flex space-x-4 mt-6">
                        <button className="bg-purple-500 hover:bg-blue-600 text-white px-6 py-2 rounded-md flex items-center space-x-2">
                            <Send className="w-5 h-5" />
                            <span>Message</span>
                        </button>
                        <button className="bg-gray-200 hover:bg-gray-300 dark:text-gray-800 px-6 py-2 rounded-md flex items-center space-x-2">
                            <UserPlus className="w-5 h-5 text-purple-600 dark:text-gray-400" />
                            <span>Follow</span>
                        </button>
                    </div>
                    <div className="flex gap-10 border-b mt-8 dark:border-gray-700">
                        <button
                            className={`pb-2 ${activeSection === "home" ? "text-purple-600 border-b-2 border-purple-600" : "text-gray-600 dark:text-gray-400"}`}
                            onClick={() => handleClick("home")}
                        >
                            Home
                        </button>
                        <button
                            className={`pb-2 ${activeSection === "about" ? "text-purple-600 border-b-2 border-purple-600" : "text-gray-600 dark:text-gray-400"}`}
                            onClick={() => handleClick("about")}
                        >
                            About
                        </button>
                        <button
                            className={`pb-2 ${activeSection === "posts" ? "text-purple-600 border-b-2 border-purple-600" : "text-gray-600 dark:text-gray-400"}`}
                            onClick={() => handleClick("posts")}
                        >
                            Posts
                        </button>
                        <button
                            className={`pb-2 ${activeSection === "courses" ? "text-purple-600 border-b-2 border-purple-600" : "text-gray-600 dark:text-gray-400"}`}
                            onClick={() => handleClick("courses")}
                        >
                            Courses
                        </button>
                        <button
                            className={`pb-2 ${activeSection === "jobs" ? "text-purple-600 border-b-2 border-purple-600" : "text-gray-600 dark:text-gray-400"}`}
                            onClick={() => handleClick("jobs")}
                        >
                            Jobs
                        </button>
                        <button
                            className={`pb-2 ${activeSection === "review" ? "text-purple-600 border-b-2 border-purple-600" : "text-gray-600 dark:text-gray-400"}`}
                            onClick={() => handleClick("review")}
                        >
                            Review
                        </button>
                    </div>
                </div>

            </div>


            <div className="mt-6 shadow-lg rounded-lg">
                {activeSection === "home" && (
                    <>
                        <div className="border rounded-lg p-3 shadow-sm dark:border-gray-800">



                            <div className="space-y-6 mb-8">
                                <div className="bg-white dark:bg-slate-900 text-gray-700 dark:text-gray-600 rounded-lg p-6">
                                    <h3 className="text-2xl font-bold mb-4 text-left text-gray-700 dark:text-gray-300 ">Overview</h3>
                                    <p className="text-gray-700 dark:text-gray-500 text-left">
                                        CollegmateCommunity is a platform designed to bridge the gap between students from all over the world. It offers a space for students to exchange knowledge, ideas...
                                    </p>
                                    <button className="mt-4 text-purple-600 hover:text-blue-700 flex items-center">
                                        Show all details
                                        <ChevronRight className="w-4 h-4 ml-1" />
                                    </button>
                                </div>

                                {/* Posts Section */}
                                <div className="bg-white dark:bg-slate-900 text-gray-700 dark:text-gray-600 rounded-lg p-6">
                                    <div className="flex justify-between items-center mb-4">
                                        <h3 className="text-2xl font-bold mb-4 text-left text-gray-700 dark:text-gray-300">Page posts</h3>
                                        <div className="flex space-x-2">
                                            <button className="p-2 rounded-full hover:bg-gray-100">
                                                <ChevronLeft className="w-5 h-5" />
                                            </button>
                                            <button className="p-2 rounded-full hover:bg-gray-100">
                                                <ChevronRight className="w-5 h-5" />
                                            </button>
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                                        {/* Post Cards */}


                                        <div className="border rounded-lg p-4 dark:border-gray-700 bg-white dark:bg-gray-900 text-gray-700 dark:text-gray-300">
                                            <div className="flex items-center mb-2">
                                                <img src="https://via.placeholder.com/40" alt="CollegeMate" className="rounded-full" />
                                                <div className="ml-2 text-left">
                                                    <p className="font-semibold">CollegeMate</p>
                                                    <p className="text-sm text-gray-500">105,330 followers • 1w</p>
                                                </div>
                                                <button className="ml-auto">
                                                    <MoreHorizontal className="w-5 h-5" />
                                                </button>
                                            </div>
                                            <p className="mb-2 text-left text-sm">Discover our beginner-friendly Machine Learning program.</p>
                                            <img src="https://images.pexels.com/photos/17771091/pexels-photo-17771091/free-photo-of-child-using-internet.jpeg?auto=compress&cs=tinysrgb&w=400" alt="Machine Learning course" className="rounded-lg w-full mb-4" />
                                            <div className="flex justify-between items-center">
                                                <button className="flex items-center space-x-1 hover:bg-gray-100 p-1 md:p-2 rounded">
                                                    <Heart className="w-3 h-3 md:w-4 md:h-4" />
                                                    <span className="text-xs md:text-sm">Like</span>
                                                </button>
                                                <button className="flex items-center space-x-1 hover:bg-gray-100 p-1 md:p-2 rounded">
                                                    <MessageCircle className="w-3 h-3 md:w-4 md:h-4" />
                                                    <span className="text-xs md:text-sm">Comment</span>
                                                </button>
                                                <button className="flex items-center space-x-1 hover:bg-gray-100 p-1 md:p-2 rounded">
                                                    <Repeat className="w-3 h-3 md:w-4 md:h-4" />
                                                    <span className="text-xs md:text-sm">Repost</span>
                                                </button>
                                                <button className="flex items-center space-x-1 hover:bg-gray-100 p-1 md:p-2 rounded">
                                                    <Share2 className="w-3 h-3 md:w-4 md:h-4" />
                                                    <span className="text-xs md:text-sm">Send</span>
                                                </button>
                                            </div>

                                        </div>

                                        {/* Post Card 2 */}
                                        <div className="border rounded-lg p-4 dark:border-gray-700 bg-white dark:bg-gray-900 text-gray-700 dark:text-gray-300">
                                            <div className="flex items-center mb-2">
                                                <img src="https://via.placeholder.com/40" alt="CollegeMate" className="rounded-full" />
                                                <div className="ml-2 text-left ">
                                                    <p className="font-semibold ">CollegeMate</p>
                                                    <p className="text-sm text-gray-500">105,330 followers • 1w</p>
                                                </div>
                                                <button className="ml-auto">
                                                    <MoreHorizontal className="w-5 h-5" />
                                                </button>
                                            </div>
                                            <p className="mb-2 text-left text-sm ">Enhance your skills with our advanced Data Science course.</p>
                                            <img src="https://images.pexels.com/photos/17771091/pexels-photo-17771091/free-photo-of-child-using-internet.jpeg?auto=compress&cs=tinysrgb&w=400" alt="Data Science course" className="rounded-lg w-full mb-4" />
                                            <div className="flex justify-between items-center">
                                                <button className="flex items-center space-x-1 hover:bg-gray-100 p-1 md:p-2 rounded">
                                                    <Heart className="w-3 h-3 md:w-4 md:h-4" />
                                                    <span className="text-xs md:text-sm">Like</span>
                                                </button>
                                                <button className="flex items-center space-x-1 hover:bg-gray-100 p-1 md:p-2 rounded">
                                                    <MessageCircle className="w-3 h-3 md:w-4 md:h-4" />
                                                    <span className="text-xs md:text-sm">Comment</span>
                                                </button>
                                                <button className="flex items-center space-x-1 hover:bg-gray-100 p-1 md:p-2 rounded">
                                                    <Repeat className="w-3 h-3 md:w-4 md:h-4" />
                                                    <span className="text-xs md:text-sm">Repost</span>
                                                </button>
                                                <button className="flex items-center space-x-1 hover:bg-gray-100 p-1 md:p-2 rounded">
                                                    <Share2 className="w-3 h-3 md:w-4 md:h-4" />
                                                    <span className="text-xs md:text-sm">Send</span>
                                                </button>
                                            </div>

                                        </div>

                                        {/* Post Card 3 */}
                                        <div className="border rounded-lg p-4 dark:border-gray-700 bg-white dark:bg-slate-900 text-gray-700 dark:text-gray-300">
                                            <div className="flex items-center mb-2">
                                                <img src="https://via.placeholder.com/40" alt="CollegeMate" className="rounded-full" />
                                                <div className="ml-2 text-left">
                                                    <p className="font-semibold">CollegeMate</p>
                                                    <p className="text-sm text-gray-500">105,330 followers • 1w</p>
                                                </div>
                                                <button className="ml-auto">
                                                    <MoreHorizontal className="w-5 h-5" />
                                                </button>
                                            </div>
                                            <p className="mb-2 text-left text-sm">Join our AI Bootcamp for hands-on experience.</p>
                                            <img src="https://images.pexels.com/photos/17771091/pexels-photo-17771091/free-photo-of-child-using-internet.jpeg?auto=compress&cs=tinysrgb&w=400" alt="AI Bootcamp" className="rounded-lg w-full mb-4" />
                                            <div className="flex justify-between items-center">
                                                <button className="flex items-center space-x-1 hover:bg-gray-100 p-1 md:p-2 rounded">
                                                    <Heart className="w-3 h-3 md:w-4 md:h-4" />
                                                    <span className="text-xs md:text-sm">Like</span>
                                                </button>
                                                <button className="flex items-center space-x-1 hover:bg-gray-100 p-1 md:p-2 rounded">
                                                    <MessageCircle className="w-3 h-3 md:w-4 md:h-4" />
                                                    <span className="text-xs md:text-sm">Comment</span>
                                                </button>
                                                <button className="flex items-center space-x-1 hover:bg-gray-100 p-1 md:p-2 rounded">
                                                    <Repeat className="w-3 h-3 md:w-4 md:h-4" />
                                                    <span className="text-xs md:text-sm">Repost</span>
                                                </button>
                                                <button className="flex items-center space-x-1 hover:bg-gray-100 p-1 md:p-2 rounded">
                                                    <Share2 className="w-3 h-3 md:w-4 md:h-4" />
                                                    <span className="text-xs md:text-sm">Send</span>
                                                </button>
                                            </div>
                                        </div>



                                    </div>

                                </div>


                                <div className="bg-white dark:bg-slate-900 text-gray-700 dark:text-gray-400 rounded-lg p-6 ">
                                    <div className="flex justify-between items-center mb-4">
                                        <h3 className="text-2xl font-bold mb-4 text-left text-gray-700 dark:text-gray-300">Past events</h3>
                                        <div className="flex space-x-2">
                                            <button className="p-2 rounded-full hover:bg-gray-100">
                                                <ChevronLeft className="w-5 h-5" />
                                            </button>
                                            <button className="p-2 rounded-full hover:bg-gray-100">
                                                <ChevronRight className="w-5 h-5" />
                                            </button>
                                        </div>
                                    </div>

                                    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
                                        {events.map((event, index) => (
                                            <div key={index} className="border rounded-lg p-4 dark:border-gray-700">
                                                <img src={event.image} alt={event.title} className="rounded-lg w-full mb-4" />
                                                <p className="text-sm text-gray-500 mb-2">{event.date}</p>
                                                <h4 className="font-semibold mb-2 text-left">{event.title}</h4>
                                                <p className="text-sm mb-2 text-left">
                                                    {event.instructor} • {event.role}
                                                </p>
                                                <p className="text-sm text-gray-500 mb-4 text-left">{event.attendees} attendees</p>
                                                <button className="w-full border rounded-full py-2 px-4 hover:bg-gray-50 dark:hover:bg-purple-500 border-purple-600 dark:text-white">
                                                    View details
                                                </button>

                                            </div>
                                        ))}
                                    </div>
                                </div>

                                {/* Job Openings Section */}
                                <div className="bg-white dark:bg-slate-900 text-gray-700 dark:text-gray-400 rounded-lg p-6">
                                    <h3 className="text-2xl font-bold mb-4 text-left text-gray-700 dark:text-gray-300">Recent job openings</h3>
                                    <div className="space-y-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                                        {jobs.map((job, index) => (
                                            <div key={index} className="flex items-start space-x-4">
                                                <img src="https://via.placeholder.com/40" alt="Intellipaat" className="rounded" />
                                                <div>
                                                    <h4 className="font-semibold text-left">{job.title}</h4>
                                                    <p className="text-sm text-gray-500 text-left">{job.location}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <button className="mt-4 text-blue-600 hover:text-blue-700 flex items-center">
                                        Show all jobs
                                        <ChevronRight className="w-4 h-4 ml-1" />
                                    </button>
                                </div>
                            </div>
                            <div>

                                <h3 className="text-2xl font-bold mb-4 text-left text-gray-700 dark:text-gray-300"> Courses</h3>

                                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
                                    {courses.slice(0, 4).map((course) => (
                                        <div
                                            key={course.id}
                                            className="bg-gray-100 dark:bg-slate-900 rounded-lg shadow-sm p-3 hover:shadow-lg"
                                        >
                                            <img
                                                src={course.image}
                                                alt={course.title}
                                                className="w-full h-44 object-cover rounded-lg mb-4"
                                            />
                                            <h4 className="font-semibold text-lg text-gray-800 dark:text-gray-100 mb-1 text-left">{course.title}</h4>

                                            {/* Ratings */}
                                            <div className="flex items-center text-yellow-400 mb-2 justify-start">
                                                {'★'.repeat(Math.floor(course.rating))}
                                                {'☆'.repeat(5 - Math.floor(course.rating))}
                                                <span className="text-gray-500 text-sm ml-2">({course.rating})</span>
                                            </div>

                                            {/* Course Cost and Company */}
                                            <div className="flex justify-between text-sm text-gray-600 dark:text-gray-400 mt-3">
                                                <span>{course.cost}</span>

                                                <span>
                                                    <i className="pi pi-clock mr-2 text-purple-600 dark:text-indigo-600"></i>
                                                    {course.hours}
                                                </span>

                                                <span className="font-bold text-white dark:text-white bg-indigo-600 dark:bg-indigo-600 px-2 py-1 rounded-lg">
                                                    {course.company || 'Featured'}
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <button className="mt-4 text-blue-600 hover:text-blue-700 flex items-center">
                                    Show all Courses
                                    <ChevronRight className="w-4 h-4 ml-1" />
                                </button>
                            </div>
                        </div>

                    </>
                )}










                {activeSection === "about" && (
                    <div className="w-full max-w-4xl mx-auto space-y-8  text-left bg-white dark:bg-slate-900 text-gray-700 dark:text-gray-400 rounded-lg border rounded-lg p-6 shadow-sm dark:border-gray-800">
                        {/* Pure Tailwind Version */}



                        <div className="space-y-6 mb-8">
                            <div>
                                <h3 className="text-xl font-semibold mb-4 text-gray-700 dark:text-gray-300">Overview</h3>
                                <p className="text-gray-600">{companyData.description}</p>
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 ">
                                <div>
                                    <h4 className="font-medium mb-2">Website</h4>
                                    <a href={companyData.website} className="text-blue-600 hover:underline">{companyData.website}</a>
                                </div>

                                <div>
                                    <h4 className="font-medium mb-2">Phone</h4>
                                    <a href={`tel:${companyData.phone}`} className="text-blue-600 hover:underline">{companyData.phone}</a>
                                </div>
                            </div>



                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <div>
                                    <h4 className="font-medium mb-2">Industry</h4>
                                    <p className="text-gray-600">{companyData.industry}</p>
                                </div>

                                <div>
                                    <h4 className="font-medium mb-2">Company size</h4>
                                    <p className="text-gray-600">{companyData.employees}</p>
                                    <p className="text-gray-600">{companyData.members}</p>
                                </div>
                            </div>

                            <div>
                                <h4 className="font-medium mb-2">Headquarters</h4>
                                <p className="text-gray-600">{companyData.headquarters}</p>
                            </div>

                            <div>
                                <h4 className="font-medium mb-4 p-3">Location</h4>
                                <div className="bg-gray-50 dark:bg-gray-500 p-4 rounded-lg">

                                    <p className="text-gray-600">{companyData.address}</p>

                                    <div className="w-full h-64 md:h-80 lg:h-96">
                                        <iframe
                                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d248849.84916296526!2d77.6309395!3d12.9539974!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1670c9b44e6d%3A0xf8dfc3e8517e4fe0!2sBengaluru%2C%20Karnataka!5e0!3m2!1sen!2sin!4v1731126317722!5m2!1sen!2sin"
                                            width="100%"
                                            height="100%"
                                            style={{ border: 0 }}
                                            allowFullScreen=""
                                            loading="lazy"
                                            referrerPolicy="no-referrer-when-downgrade"
                                        ></iframe>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                )}

                {activeSection === "posts" && (
                    <div className="w-full md:w-3/5 p-3 space-y-6 mb-8"> {/* Reduced padding here */}
                        {/* Post creation card */}
                        <div className="flex flex-wrap gap-3 mb-6 text-gray-700 dark:text-white text-md">
                            {categories.map((category, index) => (
                                <button
                                    key={index}
                                    variant={category.active ? "default" : "outline"}
                                    className={`rounded-lg py-1 px-3 ${category.active ? "bg-purple-600 dark:text-white text-gray-800" : "border-gray-300  dark:text-white text-gray-500"}`}
                                >
                                    {category.name}
                                </button>
                            ))}
                        </div>
                        {posts.map((post, index) => (
                            <div key={index} className="max-w-3xl bg-white dark:bg-slate-900 rounded-lg shadow-md p-4 mb-6">
                                {/* Header section */}
                                <div className="flex items-center justify-between mb-3">
                                    <div className="flex items-center gap-3">
                                        <img
                                            src={post.authorInfo.profilePicture}
                                            alt={`${post.authorInfo.name} profile`}
                                            className="w-10 h-10 rounded-full"
                                        />
                                        <div>
                                            <div className="flex items-center gap-2">
                                                <h3 className="font-semibold text-gray-800 dark:text-gray-200">{post.authorInfo.name}</h3>
                                                <span className="text-blue-500 text-sm">• {post.authorInfo.isFollowing ? "Following" : post.actions.followText}</span>
                                            </div>
                                            <p className="text-sm text-gray-500 dark:text-gray-400 text-left">{post.authorInfo.timeAgo}</p>
                                        </div>
                                    </div>
                                    <button className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300">
                                        •••
                                    </button>
                                </div>

                                {/* Post content */}
                                <div className="mb-4">
                                    <p className="text-gray-800 dark:text-gray-200 mb-2 text-left">
                                        {post.content.postText.split('\n').map((line, i) => (
                                            <span key={i}>
                                                {line}
                                                <br />
                                            </span>
                                        ))}
                                    </p>
                                </div>

                                {/* Image */}
                                {post.content.image && (
                                    <div className="mb-4 rounded-lg overflow-hidden">
                                        <img
                                            src={post.content.image}
                                            alt={post.content.imageAlt}
                                            className="w-full h-auto object-cover"
                                        />
                                    </div>
                                )}

                                {/* Engagement stats */}
                                <div className="flex items-center justify-between pt-3 border-t border-gray-200 dark:border-gray-700">
                                    <button className="flex items-center gap-2 text-rose-500 hover:text-rose-600">
                                        <Heart size={20} />
                                        <span className="text-sm font-medium">{post.engagementStats.likes}</span>
                                    </button>

                                    <button className="flex items-center gap-2 text-gray-500 hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300">
                                        <MessageCircle size={20} />
                                        <span className="text-sm font-medium">{post.engagementStats.comments}</span>
                                    </button>

                                    <button className="flex items-center gap-2 text-gray-500 hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300">
                                        <Share2 size={20} />
                                        <span className="text-sm font-medium">{post.engagementStats.shares}</span>
                                    </button>

                                    <div className="flex items-center gap-2 text-gray-500 dark:text-gray-400">
                                        <Eye size={20} />
                                        <span className="text-sm font-medium">{post.engagementStats.views}</span>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>
                )}

                {activeSection === "courses" && (
                    <div className=" p-5 space-y-6 mb-8">
                        {/* New Courses */}
                        <section>
                            <h3 className="text-2xl font-bold mb-4 text-left text-gray-700 dark:text-gray-300">Trending Courses</h3>

                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
                                {courses.slice(0, 4).map((course) => (
                                    <div
                                        key={course.id}
                                        className="bg-gray-200
                                        00 dark:bg-slate-900 rounded-lg shadow-sm p-5 hover:shadow-lg"
                                    >
                                        <img
                                            src={course.image}
                                            alt={course.title}
                                            className="w-full h-44 object-cover rounded-lg mb-4"
                                        />
                                        <h4 className="font-semibold text-lg text-gray-800 dark:text-gray-100 mb-1">{course.title}</h4>

                                        {/* Ratings */}
                                        <div className="flex items-center text-yellow-400 mb-2 justify-end">
                                            {'★'.repeat(Math.floor(course.rating))}
                                            {'☆'.repeat(5 - Math.floor(course.rating))}
                                            <span className="text-gray-500 text-sm ml-2">({course.rating})</span>
                                        </div>

                                        {/* Course Cost and Company */}
                                        <div className="flex justify-between text-sm text-gray-600 dark:text-gray-400 mt-3">
                                            <span>{course.cost}</span>

                                            <span>
                                                <i className="pi pi-clock mr-2 text-purple-600 dark:text-indigo-600"></i>
                                                {course.hours}
                                            </span>

                                            <span className="font-bold text-white dark:text-white bg-indigo-600 dark:bg-indigo-600 px-2 py-1 rounded-lg">
                                                {course.company || 'Featured'}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </section>
                        <section className="mt-10">
                            <h3 className="text-2xl font-bold mb-4 text-left text-gray-700 dark:text-gray-300">
                                New Courses
                            </h3>

                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                                {courses.slice(0, 4).map((course) => (
                                    <div key={course.id} className="bg-gray-200 dark:bg-slate-900 rounded-lg shadow-md p-4 hover:shadow-lg transition-shadow duration-200">
                                        <div className="relative">
                                            <img
                                                src={course.image}
                                                alt={course.title}
                                                className="w-full h-40 object-cover rounded-lg mb-4"
                                            />
                                            <div className="absolute top-2 right-2 bg-indigo-600 text-white text-xs font-semibold py-1 px-2 rounded ">
                                                New
                                            </div>
                                        </div>
                                        <h4 className="font-semibold text-lg">{course.title}</h4>

                                        <div className="flex items-center text-yellow-500 mb-1">
                                            {'★'.repeat(Math.floor(course.rating))}
                                            {'☆'.repeat(5 - Math.floor(course.rating))}
                                            <span className="text-gray-500 text-sm ml-2">({course.rating})</span>
                                        </div>

                                        <div className="flex items-center text-gray-500 text-sm mb-2">
                                            <i className="pi pi-users mr-2"></i>
                                            <span>{course.enrolled} Students Enrolled</span>
                                        </div>

                                        <div className="flex items-center justify-between text-sm text-gray-700 mt-2">
                                            <div className="flex items-center text-gray-500">
                                                <i className="pi pi-tag mr-2"></i>
                                                <span>{course.cost}</span>
                                            </div>
                                            <div className="flex items-center text-gray-500">
                                                <i className="pi pi-clock mr-2 text-purple-600 dark:text-indigo-300"></i>
                                                <span >{course.hours} Hours</span>
                                            </div>
                                        </div>

                                        {/* Additional Features */}
                                        <div className="mt-4">
                                            {/* <button className="w-full bg-indigo-600 text-white font-semibold py-2 rounded-md hover:bg-indigo-700 transition duration-200">
                                                Enroll Now
                                            </button> */}
                                            <button className="w-full bg-transparent border border-indigo-600 text-indigo-600 font-semibold py-2 rounded-md mt-2 hover:bg-indigo-600 hover:text-white transition duration-200">
                                                View Details
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </section>
                    </div>
                )}





                {activeSection === "jobs" && (
                    <div className="">
                        <div className="min-h-screen bg-white dark:bg-slate-900 text-slate-900 dark:text-white">
                            {/* Hero Section */}

                            {/* Main Content */}
                            <div className="max-w-6xl mx-auto py-10 px-6">
                                {/* Job Listings */}
                                <div className="w-full md:w-3/3 mx-auto px-4">
                                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                                        {jobs1.map((job, index) => (
                                            <div
                                                key={index}
                                                className="bg-gray-100 dark:bg-slate-900 rounded-lg p-5 transform transition-transform hover:scale-105"
                                            >
                                                <div className="flex justify-between items-start mb-3">
                                                    <div className="flex items-center gap-4">
                                                        <div className="w-10 h-10 sm:w-12 sm:h-12 bg-gray-200 rounded-full flex items-center justify-center overflow-hidden">
                                                            <img
                                                                src={job.logo}
                                                                alt={`${job.company} logo`}
                                                                className="object-cover w-full h-full"
                                                            />
                                                        </div>
                                                        <div>
                                                            <h6 className="text-sm sm:text-lg font-semibold">{job.position}</h6>
                                                            <div className="flex text-gray-500 dark:text-gray-400 text-xs sm:text-sm">
                                                                <span>{job.company}</span>
                                                                <span className="mx-1 sm:mx-2">•</span>
                                                                <span>{job.applicants}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button className="text-gray-500 dark:text-purple-600 text-xl">
                                                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z" />
                                                        </svg>
                                                    </button>
                                                </div>

                                                {/* Tags section */}
                                                <div className="flex flex-wrap gap-2 mb-3">
                                                    {job.tags.map((tag, i) => {
                                                        let tagBgColor;
                                                        switch (i % 4) {
                                                            case 0:
                                                                tagBgColor = 'bg-purple-200 text-purple-800';
                                                                break;
                                                            case 1:
                                                                tagBgColor = 'bg-orange-200 text-orange-800';
                                                                break;
                                                            case 2:
                                                                tagBgColor = 'bg-red-200 text-red-800';
                                                                break;
                                                            case 3:
                                                                tagBgColor = 'bg-green-200 text-green-800';
                                                                break;
                                                            default:
                                                                tagBgColor = 'bg-gray-200 text-gray-800';
                                                        }
                                                        return (
                                                            <span
                                                                key={i}
                                                                className={`${tagBgColor} rounded-lg px-2 py-1 text-xs`}
                                                            >
                                                                {tag}
                                                            </span>
                                                        );
                                                    })}
                                                </div>

                                                <p className="text-gray-500 dark:text-gray-400 text-sm text-left">
                                                    {job.description}
                                                </p>
                                                <hr className="mt-4 border-gray-200 dark:border-gray-600" />

                                                {/* New section for Posted On and Price per Hour */}
                                                <div className="mt-4 flex justify-between items-center text-xs sm:text-sm">
                                                    <span className="font-semibold">{job.salary}</span>
                                                    <div className="flex items-center text-gray-500">
                                                        <Clock className="w-4 h-4 mr-1" />
                                                        {job.postedTime}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}


                {activeSection === "review" && (
                    <div className="bg-white dark:bg-slate-900 rounded-lg shadow-lg p-6">
                        <div className="flex justify-between items-center mb-6">


                        </div>

                        {/* Stats Section */}
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
                            <div>
                                <h3 className="text-lg font-semibold mb-2 text-left">Total Reviews</h3>
                                <div className="text-3xl font-bold text-left">{reviewsData.totalReviews}</div>
                                <div className="text-green-500 text-sm text-left">↑ {reviewsData.growth} Growth in reviews on this year</div>
                            </div>

                            <div>
                                <h3 className="text-lg font-semibold mb-2 text-left">Average Rating</h3>
                                <div className="flex items-center space-x-2text-left">
                                    <span className="text-3xl font-bold text-left">{reviewsData.averageRating}</span>
                                    <StarRating rating={4} />
                                </div>
                                <div className="text-gray-500 text-sm text-left">Average rating on this year</div>
                            </div>

                            <div>
                                <h3 className="text-lg font-semibold mb-2 text-left">Rating Distribution</h3>
                                {reviewsData.ratingDistribution.map((dist) => (
                                    <div key={dist.stars} className="flex items-center space-x-2 text-sm">
                                        <span className="w-4">{dist.stars}</span>
                                        <div className="flex-grow h-2 bg-gray-200 rounded-full overflow-hidden">
                                            <div
                                                className="h-full bg-green-500 rounded-full"
                                                style={{ width: `${(parseInt(dist.count) / 20)}%` }}
                                            />
                                        </div>
                                        <span className="w-12">{dist.count}</span>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/* Reviews List */}
                        <div className="space-y-6">
                            {reviewsData.reviews.map((review) => (
                                <div key={review.id} className="border-b pb-6 dark:border-gray-800">
                                    <div className="flex items-start space-x-4">
                                        <img
                                            src={review.avatar}
                                            alt={review.name}
                                            className="w-12 h-12 rounded-full"
                                        />
                                        <div className="flex-grow">
                                            <div className="flex justify-between">
                                                <h4 className="font-semibold">{review.name}</h4>
                                                <div className="flex items-center space-x-2">
                                                    <StarRating rating={review.rating} />
                                                    <span className="text-gray-500">{review.date}</span>
                                                </div>
                                            </div>
                                            <div className="text-sm text-gray-500 mt-1 text-left">
                                                <div>Total Spend: {review.totalSpend}</div>
                                                <div>Total Review: {review.totalReviews}</div>
                                            </div>
                                            <p className="mt-2 text-left">{review.comment}</p>
                                            <div className="flex space-x-4 mt-4">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div >


        </>
    );
};

export default Company;











