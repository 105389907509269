import React, { useState } from 'react';
import { HiOutlineSearch, HiMenu } from 'react-icons/hi';
import { MdSettings, MdHelpOutline, MdAccountCircle } from 'react-icons/md';
import { InputText } from 'primereact/inputtext';
import { links } from './Data'; // Import your links data
import './styles.css';
import { FaBookOpen, FaBuilding, FaUser } from 'react-icons/fa';

const Sidebar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false); // State to handle dropdown visibility

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen); // Toggle the menu open state
    };

    return (
        <>
            {/* Updated sidebar with Tailwind utility classes for visibility control */}
            <div className="fixed inset-y-0 left-0 z-10 w-64 bg-white dark:bg-gray-900 p-6 border-r border-gray-200 dark:border-gray-700 sidebar hidden md:block">
                <div className="flex items-center mb-6">
                    <a href="/dashboard" className="header-logo h-8 w-auto mr-2"></a>
                </div>



                <nav className="mt-6 flex-1 flex flex-col justify-between">
                    <div>
                        {links && links.map((link, index) => {
                            const { id, url, text, icon } = link;
                            return (
                                <a
                                    key={id}
                                    href={url}
                                    className={`flex items-center px-4 py-2 rounded-md ${index === 0
                                        ? ' text-gray-700 dark:text-gray-200'
                                        : 'mt-5 text-gray-600 dark:text-gray-400 hover:bg-gray-200 dark:hover:bg-gray-700 hover:text-gray-700 dark:hover:text-gray-300 transition-colors duration-200'
                                        }`}
                                >
                                    <span className={`mr-2 ${index === 0 ? 'text-gray-700 dark:text-gray-200' : 'text-gray-600 dark:text-gray-400'}`}>
                                        {icon}
                                    </span>
                                    <span className={`mx-4 font-medium ${index === 0 ? 'text-gray-700 dark:text-gray-200' : 'text-gray-600 dark:text-gray-400'}`}>
                                        {text}
                                    </span>
                                </a>
                            );
                        })}
                    </div>
                </nav>


                <hr className="my-6 border-purple-200 dark:border-gray-700 gap-6" />

                <div className="mt-4 space-y-5"> {/* Added space-y-2 for gap */}

                    <a
                        href="/company-profile"
                        className="flex items-center px-4 py-2 rounded-md text-gray-600 dark:text-gray-400 hover:bg-gray-200 dark:hover:bg-gray-700 hover:text-gray-700 dark:hover:text-gray-300 transition-colors transform"
                    >
                        <FaBuilding className="w-5 h-5" />
                        <span className="mx-4 font-medium">Company</span>
                    </a>
                    <a
                        href="/mentorship"
                        className="flex items-center px-4 py-2 rounded-md text-gray-600 dark:text-gray-400 hover:bg-gray-200 dark:hover:bg-gray-700 hover:text-gray-700 dark:hover:text-gray-300 transition-colors transform"
                    >
                        <FaUser className="w-5 h-5" />
                        <span className="mx-4 font-medium">MentorShip</span>
                    </a>

                    <a
                        href="/admin-dashboard"
                        className="flex items-center px-4 py-2 rounded-md text-gray-600 dark:text-gray-400 hover:bg-gray-200 dark:hover:bg-gray-700 hover:text-gray-700 dark:hover:text-gray-300 transition-colors transform"
                    >
                        <MdAccountCircle className="w-5 h-5" />
                        <span className="mx-4 font-medium">Admin</span>
                    </a>
                    {/* <a
                        href="/settings"
                        className="flex items-center px-4 py-2 rounded-md text-gray-600 dark:text-gray-400 hover:bg-gray-200 dark:hover:bg-gray-700 hover:text-gray-700 dark:hover:text-gray-300 transition-colors transform"
                    >
                        <MdSettings className="w-5 h-5" />
                        <span className="mx-4 font-medium">Account</span>
                    </a> */}
                    <a
                        href="/help-center"
                        className="flex items-center px-4 py-2 rounded-md text-gray-600 dark:text-gray-400 hover:bg-gray-200 dark:hover:bg-gray-700 hover:text-gray-700 dark:hover:text-gray-300 transition-colors transform"
                    >
                        <MdHelpOutline className="w-5 h-5" />
                        <span className="mx-4 font-medium">Help Center</span>
                    </a>
                </div>

                <div className="bg-indigo-100 dark:bg-gray-900 p-6 relative overflow-hidden rounded-lg">
                    {/* Decorative Elements */}
                    <div className="absolute top-4 right-4 w-2 h-2 bg-violet-400 rotate-45"></div>
                    <div className="absolute bottom-12 left-4 w-2 h-2 bg-green-400 rounded-full"></div>
                    <div className="absolute top-12 left-6 w-3 h-3 border-2 border-violet-400 rotate-12"></div>
                    <div className="flex justify-center space-x-4 mb-4">
                        {/* Play Store Button */}
                        <a href="https://play.google.com" target="_blank" rel="noopener noreferrer">
                            <div className="w-8 h-8 rounded-full bg-gray-100 flex items-center justify-center shadow-md hover:shadow-lg transition-shadow">
                                {/* Play Store SVG */}
                                <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 32 32" viewBox="0 0 32 32" id="google-play">
                                    <path fill="#f5e6fe" d="m26 32h-20c-3.314 0-6-2.686-6-6v-20c0-3.314 2.686-6 6-6h20c3.314 0 6 2.686 6 6v20c0 3.314-2.686 6-6 6z"></path>
                                    <path fill="indigo" d="M20.112 18.558l-1.91-1.878-7.015 6.917zM20.112 13.445l-8.925-5.038 7.015 6.916z"></path>
                                    <g fill="indigo">
                                        <path d="M22.909 16.951c.572-.447.572-1.454-.038-1.9l-1.872-1.066-2.088 2.016 2.088 2.016zM9.356 24l8.156-8.001-8.156-7.998v-.001c-.413.213-.69.6-.69 1.104v13.793c.001.503.277.89.69 1.103z"></path>
                                    </g>
                                </svg>
                            </div>
                        </a>

                        {/* App Store Button */}
                        <a href="https://www.apple.com/app-store/" target="_blank" rel="noopener noreferrer">
                            <div className="w-8 h-8 rounded-full bg-gray-100 flex items-center justify-center shadow-md hover:shadow-lg transition-shadow">
                                {/* App Store SVG */}
                                <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 32 32" viewBox="0 0 32 32" id="app-store">
                                    <path fill="#F5E6FE" d="M26,32H6c-3.314,0-6-2.686-6-6V6c0-3.314,2.686-6,6-6h20c3.314,0,6,2.686,6,6v20C32,29.314,29.314,32,26,32z"></path>
                                    <path fill="indigo" d="M17.397,17.996c0.287-0.538-0.084-1.316-0.745-1.316H14.61l2.823-4.887c0.18-0.317,0.074-0.72-0.242-0.9c-0.317-0.18-0.719-0.074-0.9,0.242l-0.293,0.506l-0.286-0.505c-0.184-0.317-0.584-0.423-0.9-0.242c-0.317,0.184-0.423,0.584-0.242,0.9l0.671,1.164l-2.149,3.722h-1.678c-0.364,0-0.658,0.294-0.658,0.658c0,0.364,0.293,0.658,0.658,0.658H17.397z"></path>
                                    <path fill="indigo" d="M16,24c4.42,0,8-3.581,8-8s-3.581-8-8-8s-8,3.581-8,8S11.58,24,16,24z M16,9.032c3.829,0,6.968,3.1,6.968,6.968c0,3.829-3.1,6.968-6.968,6.968c-3.829,0-6.968-3.1-6.968-6.968C9.032,12.171,12.132,9.032,16,9.032z"></path>
                                    <path fill="indigo" d="M12.154 18.301l-.471.813c-.18.317-.074.72.242.9.317.18.72.074.9-.242l.633-1.097-.001.001C13.115 18.262 12.683 18.136 12.154 18.301zM16.968 13.328c-.391.323-.785 1.277-.233 2.229.538.936 1.352 2.339 2.432 4.213.184.317.584.423.9.242.317-.184.423-.584.242-.9L19.667 18h.952c.364 0 .658-.294.658-.658 0-.364-.293-.658-.658-.658h-1.713v-.001C18.045 15.19 17.4 14.07 16.968 13.328z"></path>
                                </svg>
                            </div>
                        </a>
                    </div>
                    <h3 className="font-semibold text-1xl text-gray-500 dark:text-white mb-2 text-center">
                        Download App
                    </h3>



                </div>



            </div>

            {/* Bottom Navigation for Smaller Devices */}
            <div className="fixed bottom-0 left-0 right-0 bg-white shadow-lg dark:bg-gray-900 md:hidden z-50">
                <nav className="flex justify-around py-2">
                    {links.map((link) => (
                        <a key={link.id} href={link.url} className="flex flex-col items-center text-gray-600">
                            <span className="mb-1">{link.icon}</span> {/* Add margin below the icon */}
                            <span className="text-xs font-semibold">{link.text}</span>
                        </a>
                    ))}

                    {/* Menu Button for more options */}
                    <button onClick={toggleMenu} className="flex flex-col items-center text-gray-600">
                        <HiMenu className="w-6 h-6 mb-1" />
                        <span className="text-xs font-semibold">Menu</span>
                    </button>
                </nav>
            </div>

            {/* Dropdown Menu for the Menu Button */}
            {isMenuOpen && (
                <div className="fixed bottom-16 left-0 right-0 z-20 bg-white dark:bg-gray-900 shadow-md rounded-md p-4 gap-4">

                    <a
                        href="/company-profile"
                        className="flex items-center px-4 py-2 mt-2 rounded-md text-gray-600 dark:text-gray-400 hover:bg-gray-200 dark:hover:bg-gray-700 hover:text-gray-700 dark:hover:text-gray-300 transition-colors transform"
                    >
                        <FaBuilding className="w-5 h-5" />
                        <span className="mx-4 font-medium">Company </span>
                    </a>
                    <a
                        href="/mentorship"
                        className="flex items-center px-4 py-2 mt-2 rounded-md text-gray-600 dark:text-gray-400 hover:bg-gray-200 dark:hover:bg-gray-700 hover:text-gray-700 dark:hover:text-gray-300 transition-colors transform"
                    >
                        <FaUser className="w-5 h-5" />
                        <span className="mx-4 font-medium">MentorShip</span>
                    </a>


                    <a
                        href="/admin-dashboard"
                        className="flex items-center px-4 py-2 mt-2 rounded-md text-gray-600 dark:text-gray-400 hover:bg-gray-200 dark:hover:bg-gray-700 hover:text-gray-700 dark:hover:text-gray-300 transition-colors transform"
                    >
                        <MdAccountCircle className="w-5 h-5" />
                        <span className="mx-4 font-medium">Admin </span>
                    </a>

                    <a
                        href="/help-center"
                        className="flex items-center px-4 py-2 mt-2 rounded-md text-gray-600 dark:text-gray-400 hover:bg-gray-200 dark:hover:bg-gray-700 hover:text-gray-700 dark:hover:text-gray-300 transition-colors transform"
                    >
                        <MdHelpOutline className="w-5 h-5" />
                        <span className="mx-4 font-medium">Help Center</span>
                    </a>
                </div>
            )}
        </>
    );
};

export default Sidebar;
