import React from 'react';
import { Heart, MessageCircle, Share2, Eye, Trophy, Star, Mic, Users, BookOpen } from 'lucide-react';


import { Image, Film, BarChart2, Smile } from 'lucide-react';
const DashboardContent = () => {
    // Trending topics
    // Shouts data
    const shoutouts = [
        {
            icon: <Trophy className="w-5 h-5 text-yellow-500" />,
            title: "Outstanding Performance Award",
            description: "Sarah achieved highest course completion rate",
            date: "2 hours ago",
            bgColor: "bg-yellow-50"
        },
        {
            icon: <MessageCircle className="w-5 h-5 text-blue-500" />,
            title: "Most Helpful Student",
            description: "John helped 15 peers with their projects",
            date: "5 hours ago",
            bgColor: "bg-blue-50"
        },
        {
            icon: <Star className="w-5 h-5 text-purple-500" />,
            title: "Perfect Attendance",
            description: "Emily maintained 100% attendance this month",
            date: "1 day ago",
            bgColor: "bg-purple-50"
        },
        {
            icon: <BookOpen className="w-5 h-5 text-green-500" />,
            title: "Top Researcher",
            description: "David published 3 papers in top journals",
            date: "3 days ago",
            bgColor: "bg-green-50"
        },
        {
            icon: <Users className="w-5 h-5 text-red-500" />,
            title: "Best Team Collaboration",
            description: "The AI Research Group completed the final project early",
            date: "2 days ago",
            bgColor: "bg-red-50"
        },
        {
            icon: <Mic className="w-5 h-5 text-pink-500" />,
            title: "Student Speaker Award",
            description: "Sophia delivered an inspiring graduation speech",
            date: "5 days ago",
            bgColor: "bg-pink-50"
        }

    ];


    const topCourses = [
        {
            id: "01",
            title: "Digital Illustration with Procreate",
            timeAgo: "2 weeks ago",
            icon: "https://images.pexels.com/photos/158826/structure-light-led-movement-158826.jpeg?auto=compress&cs=tinysrgb&w=400", // Illustration sample image
            bgColor: "bg-blue-50"
        },
        {
            id: "02",
            title: "Crafting Beautiful Pottery at Home",
            timeAgo: "1 month ago",
            icon: "https://images.pexels.com/photos/159644/art-supplies-brushes-rulers-scissors-159644.jpeg?auto=compress&cs=tinysrgb&w=400", // Pottery sample image
            bgColor: "bg-indigo-50"
        },
        {
            id: "03",
            title: "Building Websites with HTML & CSS",
            timeAgo: "1 month ago",
            icon: "https://images.pexels.com/photos/65438/pexels-photo-65438.jpeg?auto=compress&cs=tinysrgb&w=400", // Web development sample image
            bgColor: "bg-pink-50"
        },
        {
            id: "04",
            title: "Graphic Design Essentials with Adobe",
            timeAgo: "2 months ago",
            icon: "https://images.pexels.com/photos/1601773/pexels-photo-1601773.jpeg?auto=compress&cs=tinysrgb&w=400", // Graphic design sample image
            bgColor: "bg-blue-50"
        },
        {
            id: "05",
            title: "Mastering Photography Basics",
            timeAgo: "2 months ago",
            icon: "https://images.pexels.com/photos/7727555/pexels-photo-7727555.jpeg?auto=compress&cs=tinysrgb&w=400", // Photography sample image
            bgColor: "bg-purple-50"
        },
        {
            id: "06",
            title: "Typography Fundamentals for Designers",
            timeAgo: "2 months ago",
            icon: "https://images.pexels.com/photos/54257/pexels-photo-54257.jpeg?auto=compress&cs=tinysrgb&w=400", // Typography sample image
            bgColor: "bg-gray-50"
        },
        {
            id: "07",
            title: "Introduction to Cloud Computing with AWS",
            timeAgo: "3 months ago",
            icon: "https://images.pexels.com/photos/1148820/pexels-photo-1148820.jpeg?auto=compress&cs=tinysrgb&w=400", // Cloud computing sample image
            bgColor: "bg-teal-50"
        }
    ];



    const posts = [
        {
            authorInfo: {
                name: "Ali Husni",
                profilePicture: "https://images.pexels.com/photos/6115039/pexels-photo-6115039.jpeg?auto=compress&cs=tinysrgb&w=400",
                timeAgo: "25 minutes ago",
                isFollowing: false
            },
            content: {
                postText: `🎓 Exciting News! Our college is offering a new course in Artificial Intelligence starting next semester! 
                            Don't miss the chance to learn from industry experts and boost your career. Enroll now!`,
                image: "https://images.pexels.com/photos/256542/pexels-photo-256542.jpeg?auto=compress&cs=tinysrgb&w=400",
                imageAlt: "AI Course Announcement"
            },
            engagementStats: {
                likes: "250 likes",
                comments: "45 Comment",
                shares: "30x Share",
                views: "1.2k Views"
            },
            actions: {
                followText: "Follow",
                likeIcon: "Heart",
                commentIcon: "MessageCircle",
                shareIcon: "Share2",
                viewIcon: "Eye"
            }
        },
        {
            authorInfo: {
                name: "Sara Ahmed",
                profilePicture: "https://images.pexels.com/photos/6189629/pexels-photo-6189629.jpeg?auto=compress&cs=tinysrgb&w=400",
                timeAgo: "1 hour ago",
                isFollowing: false
            },
            content: {
                postText: `📅 Mark Your Calendars! Join us for the Annual Career Fair this Friday! 
                            Network with top companies, discover internships, and find job opportunities!`,
                image: "https://images.pexels.com/photos/1438072/pexels-photo-1438072.jpeg?auto=compress&cs=tinysrgb&w=400",
                imageAlt: "Career Fair Event"
            },
            engagementStats: {
                likes: "400 likes",
                comments: "60 Comment",
                shares: "50x Share",
                views: "3.5k Views"
            },
            actions: {
                followText: "Follow",
                likeIcon: "Heart",
                commentIcon: "MessageCircle",
                shareIcon: "Share2",
                viewIcon: "Eye"
            }
        },
        {
            authorInfo: {
                name: "John Doe",
                profilePicture: "https://images.pexels.com/photos/3765130/pexels-photo-3765130.jpeg?auto=compress&cs=tinysrgb&w=400",
                timeAgo: "2 hours ago",
                isFollowing: true
            },
            content: {
                postText: `🎉 Join us for a Workshop on Resume Building next week! 
                            Learn tips and tricks to create an impressive resume and land your dream job!`,
                image: "https://images.pexels.com/photos/5905774/pexels-photo-5905774.jpeg?auto=compress&cs=tinysrgb&w=400",
                imageAlt: "Resume Building Workshop"
            },
            engagementStats: {
                likes: "320 likes",
                comments: "25 Comment",
                shares: "15x Share",
                views: "2.1k Views"
            },
            actions: {
                followText: "Follow",
                likeIcon: "Heart",
                commentIcon: "MessageCircle",
                shareIcon: "Share2",
                viewIcon: "Eye"
            }
        },
        {
            authorInfo: {
                name: "Emily Johnson",
                profilePicture: "https://images.pexels.com/photos/6695047/pexels-photo-6695047.jpeg?auto=compress&cs=tinysrgb&w=400",
                timeAgo: "3 hours ago",
                isFollowing: false
            },
            content: {
                postText: `📚 Don't forget to register for our Summer Internship Program! 
                            Gain valuable experience and build your professional network while studying!`,
                image: "https://images.pexels.com/photos/3184306/pexels-photo-3184306.jpeg?auto=compress&cs=tinysrgb&w=400",
                imageAlt: "Summer Internship Program"
            },
            engagementStats: {
                likes: "180 likes",
                comments: "12 Comment",
                shares: "20x Share",
                views: "850 Views"
            },
            actions: {
                followText: "Follow",
                likeIcon: "Heart",
                commentIcon: "MessageCircle",
                shareIcon: "Share2",
                viewIcon: "Eye"
            }
        },
        {
            authorInfo: {
                name: "Michael Brown",
                profilePicture: "https://images.pexels.com/photos/7879360/pexels-photo-7879360.jpeg?auto=compress&cs=tinysrgb&w=400",
                timeAgo: "4 hours ago",
                isFollowing: false
            },
            content: {
                postText: `🌟 Excited to announce our Guest Lecture series featuring industry leaders! 
                            First session is on Digital Marketing - don't miss out on this opportunity to learn!`,
                image: "https://images.pexels.com/photos/3184190/pexels-photo-3184190.jpeg?auto=compress&cs=tinysrgb&w=400",
                imageAlt: "Guest Lecture Series"
            },
            engagementStats: {
                likes: "560 likes",
                comments: "80 Comment",
                shares: "40x Share",
                views: "4.8k Views"
            },
            actions: {
                followText: "Follow",
                likeIcon: "Heart",
                commentIcon: "MessageCircle",
                shareIcon: "Share2",
                viewIcon: "Eye"
            }
        }
    ];



    return (
        <>
            <div className="mb-6">

                <div className="flex items-center text-sm text-gray-500">
                    <span className="hover:text-blue-600 cursor-pointer">Home</span>
                    <span className="mx-2">›</span>
                    <span className=' text-purple-600'>Dashboard</span>
                </div>
            </div>

            <div className="flex flex-col md:flex-row min-h-screen bg-gray-50 dark:bg-slate-950">

                {/* Left column */}
                <div className="w-full md:w-3/5 p-3"> {/* Reduced padding here */}
                    {/* Post creation card */}
                    <div className="bg-white dark:bg-gray-900 text-gray-800 dark:text-gray-100 rounded-lg p-6 mb-6 shadow-md">
                        {/* Header Section */}
                        <div className="flex flex-wrap items-center mb-4">
                            <img
                                src="https://images.pexels.com/photos/6115039/pexels-photo-6115039.jpeg?auto=compress&cs=tinysrgb&w=400"
                                alt="User avatar"
                                className="w-10 h-10 rounded-full"
                            />
                            <div className="flex-grow">
                                <input
                                    type="text"
                                    placeholder="Start post in this group"
                                    className="w-full px-4 py-2 bg-gray-100 dark:bg-gray-800 rounded-full 
               text-gray-700 dark:text-gray-200 placeholder-gray-500 
               dark:placeholder-gray-400 focus:outline-none focus:ring-2 
               focus:ring-blue-500 dark:focus:ring-blue-400 ml-4 dark:ml-4"
                                />


                            </div>
                        </div>

                        {/* Action buttons */}
                        <div className="flex items-center justify-between px-2">
                            <button className="flex items-center gap-2 px-4 py-2 rounded-lg 
                         hover:bg-gray-100 dark:hover:bg-gray-700 
                         text-gray-600 dark:text-gray-300 transition-colors">
                                <Image size={20} className="text-blue-500" />
                                <span className="text-sm font-medium">Image</span>
                            </button>

                            <button className="flex items-center gap-2 px-4 py-2 rounded-lg 
                         hover:bg-gray-100 dark:hover:bg-gray-700 
                         text-gray-600 dark:text-gray-300 transition-colors">
                                <Film size={20} className="text-green-500" />
                                <span className="text-sm font-medium">Video / Gif</span>
                            </button>

                            <button className="flex items-center gap-2 px-4 py-2 rounded-lg 
                         hover:bg-gray-100 dark:hover:bg-gray-700 
                         text-gray-600 dark:text-gray-300 transition-colors">
                                <BarChart2 size={20} className="text-purple-500" />
                                <span className="text-sm font-medium">Activity / Poll</span>
                            </button>

                            <button className="flex items-center gap-2 px-4 py-2 rounded-lg 
                         hover:bg-gray-100 dark:hover:bg-gray-700 
                         text-gray-600 dark:text-gray-300 transition-colors">
                                <Smile size={20} className="text-yellow-500" />
                                <span className="text-sm font-medium">Feelings</span>
                            </button>
                        </div>
                    </div>





                    {posts.map((post, index) => (
                        <div key={index} className="max-w-3xl bg-white dark:bg-gray-900 rounded-lg shadow-md p-4 mb-6">
                            {/* Header section */}
                            <div className="flex items-center justify-between mb-3">
                                <div className="flex items-center gap-3">
                                    <img
                                        src={post.authorInfo.profilePicture}
                                        alt={`${post.authorInfo.name} profile`}
                                        className="w-10 h-10 rounded-full"
                                    />
                                    <div>
                                        <div className="flex items-center gap-2">
                                            <h3 className="font-semibold text-gray-800 dark:text-gray-200">{post.authorInfo.name}</h3>
                                            <span className="text-blue-500 text-sm">• {post.authorInfo.isFollowing ? "Following" : post.actions.followText}</span>
                                        </div>
                                        <p className="text-sm text-gray-500 dark:text-gray-400 text-left">{post.authorInfo.timeAgo}</p>
                                    </div>
                                </div>
                                <button className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300">
                                    •••
                                </button>
                            </div>

                            {/* Post content */}
                            <div className="mb-4">
                                <p className="text-gray-800 dark:text-gray-200 mb-2 text-left">
                                    {post.content.postText.split('\n').map((line, i) => (
                                        <span key={i}>
                                            {line}
                                            <br />
                                        </span>
                                    ))}
                                </p>
                            </div>

                            {/* Image */}
                            {post.content.image && (
                                <div className="mb-4 rounded-lg overflow-hidden">
                                    <img
                                        src={post.content.image}
                                        alt={post.content.imageAlt}
                                        className="w-full h-auto object-cover"
                                    />
                                </div>
                            )}

                            {/* Engagement stats */}
                            <div className="flex items-center justify-between pt-3 border-t border-gray-200 dark:border-gray-700">
                                <button className="flex items-center gap-2 text-rose-500 hover:text-rose-600">
                                    <Heart size={20} />
                                    <span className="text-sm font-medium">{post.engagementStats.likes}</span>
                                </button>

                                <button className="flex items-center gap-2 text-gray-500 hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300">
                                    <MessageCircle size={20} />
                                    <span className="text-sm font-medium">{post.engagementStats.comments}</span>
                                </button>

                                <button className="flex items-center gap-2 text-gray-500 hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300">
                                    <Share2 size={20} />
                                    <span className="text-sm font-medium">{post.engagementStats.shares}</span>
                                </button>

                                <div className="flex items-center gap-2 text-gray-500 dark:text-gray-400">
                                    <Eye size={20} />
                                    <span className="text-sm font-medium">{post.engagementStats.views}</span>
                                </div>
                            </div>
                        </div>
                    ))}

                </div>




                <div className="max-w-sm mx-auto w-full md:w-1/3 p-3 dark:bg-slate-950 space-y-4 font-sans mb-18"> {/* Added mb-16 */}
                    {/* Trending Now Section */}
                    <div className="max-w-md bg-white shadow-md dark:bg-gray-900 rounded-xl p-4">
    <h2 className="text-lg font-semibold text-gray-800 dark:text-white mb-4 text-left">
        Top Shoutouts
    </h2>

    <div className="space-y-3">
        {shoutouts.map((item, index) => (
            <div
                key={index}
                className="group relative flex gap-3 p-3 rounded-lg transition-all duration-200"
            >
                {/* Icon container */}
                <div className={`${item.bgColor} w-8 h-8 flex items-center justify-center rounded-lg`}>
                    {item.icon}
                </div>

                {/* Content */}
                <div className="flex-1">
                    <h3 className="font-medium text-gray-900 dark:text-white mb-1 text-left">
                        {item.title}
                    </h3>
                    <p className="text-sm text-gray-600 mb-1 text-left">
                        {item.description}
                    </p>
                    <div className="flex justify-between items-center">
                        <span className="text-xs text-gray-500">
                            {item.date}
                        </span>
                        <button className="text-sm text-blue-500 hover:text-blue-600 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                            Mark as read
                        </button>
                    </div>
                </div>
            </div>
        ))}
    </div>
</div>





                    <div className="bg-indigo-100 dark:bg-gray-900  rounded-lg p-4 mb-4">
                        {/* Image */}
                        <img
                            src="/assets/images/svg.png" // Replace with your image URL
                            alt="Promo"
                            className="w-full h-32 object-cover rounded-md mb-4"
                        />

                        <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200 mb-2">
                            Special Offer!
                        </h3>

                        <p className="text-gray-600 text-sm mb-2">
                            get a <span className="text-indigo-600 font-bold">-20% discount</span> on it
                        </p>
                        <button
                            className="w-full bg-indigo-600 text-white py-2 px-4 rounded-full hover:bg-blue-600 transition-colors duration-300 font-medium"
                        >
                            Join Course
                        </button>
                    </div>


                    {/* Trending Courses Section */}
                    <div className="bg-white dark:bg-gray-900 text-gray-800 dark:text-gray-100 rounded-lg shadow-lg p-6 mb-4">
    <h2 className="text-xl font-semibold mb-6 text-left dark:text-white">Top Courses</h2>
    <div className="space-y-4">
        {topCourses.map((course) => (
            <div
                key={course.id}
                className="flex items-center space-x-4 p-2 hover:bg-gray-200 dark:hover:bg-gray-800 rounded-lg transition-colors duration-200 cursor-pointer text-left"
            >
                <span className="text-gray-400 min-w-[24px] text-sm">
                    {course.id}
                </span>

                <div className={`${course.bgColor} p-2 dark:bg-gray-800 rounded-xl`}>
                    <img
                        src={course.icon}
                        alt={course.title}
                        className="w-8 h-8 object-cover rounded"
                    />
                </div>
                <div className="flex-1">
                    <h3 className="font-medium text-gray-700 dark:text-white">
                        {course.title}
                    </h3>
                    <p className="text-sm text-gray-500">
                        {course.timeAgo}
                    </p>
                </div>
            </div>
        ))}
    </div>
</div>




                </div>

            </div>

        </>

    );
};

export default DashboardContent;
