// actions.js

import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
} from './Types';

// Action creators for login
export const loginRequest = () => ({
  type: LOGIN_REQUEST,
});

export const loginSuccess = (user) => ({
  type: LOGIN_SUCCESS,
  payload: user,
});

export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
});



// Action creator for logout
export const logout = () => async (dispatch) => {
  try {
    // Clear only the token from localStorage
    localStorage.removeItem('authToken');

    dispatch({
      type: LOGOUT,
    });
  } catch (error) {
    console.error('Error during logout:', error);
  }
};





// Asynchronous action creator for login (using Redux Thunk)
export const loginUser = (credentials) => {
  return async (dispatch) => {
    try {
      dispatch(loginRequest());

      // Store only the token in localStorage
      localStorage.setItem('authToken', credentials.token);
      console.log('Stored Token:', localStorage.getItem('authToken'));

      // Dispatch the success action with only the token
      dispatch(loginSuccess({ token: credentials.token }));
    } catch (error) {
      dispatch(loginFailure(error.message));
    }
  };
};




// Action to retrieve user data from localStorage
export const retrieveUserFromLocalStorage = () => {
  return async (dispatch) => {
    try {
      console.log('Retrieving token from localStorage...');
      const authToken = localStorage.getItem('authToken');
      console.log('Retrieved Token:', authToken);

      if (authToken) {
        // Directly dispatch login success with the token
        dispatch(loginSuccess({ token: authToken }));
      } else {
        console.warn('No valid token found in localStorage.');
      }
    } catch (error) {
      console.error('Error retrieving token from LocalStorage:', error);
    }
  };
};
