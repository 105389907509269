import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { HiOutlineSearch } from 'react-icons/hi';
import ThemeSwitcher from './ThemeSwitcher';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
const Dheader = () => {
    const [searchVisible, setSearchVisible] = useState(false);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate(); // For navigation
    const toggleSearchBar = () => {
        setSearchVisible(!searchVisible);
    };

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };
    const handleLogout = () => {
        // Remove token from localStorage
        localStorage.removeItem('authToken');
        // Optionally dispatch an action to clear Redux state
        dispatch({ type: 'LOGOUT' });
        // Redirect or refresh page
        window.location.href = '/login';  // Redirect to login page
      };
      
    return (
        <header className="bg-white dark:bg-slate-950 text-gray-800 dark:text-white px-4 py-3 flex justify-between items-center rounded-lg">
            {/* Logo for Mobile/Tablet */}
            <div className="flex lg:hidden">
                <a href="#" className="header-logo h-8 w-auto">
                    {/* Optional logo */}
                </a>
            </div>

            {/* Greeting for Desktop */}
            <div className="hidden lg:flex items-center text-lg font-semibold">
                <span className="mr-2">👋</span>
                <span className="text-blue-600 dark:text-purple-600">Welcome back,</span>
                <span className="font-extrabold ml-1 text-2xl">Tanisha!</span>
            </div>

            {/* Search Bar, Profile, and Theme Switcher */}
            <div className="flex items-center space-x-4 relative">
                {/* Search Bar with Icon for large screens */}
                <div className="hidden lg:flex w-full max-w-md relative">
                    <InputText
                        placeholder="Search..."
                        className="w-full bg-gray-50 dark:bg-gray-800 text-gray-800 dark:text-white rounded-lg p-2 pl-10"
                    />
                    <HiOutlineSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-800 dark:text-white" size={20} />
                </div>

                {/* Search Icon for small screens */}
                <div className="lg:hidden">
                    <button onClick={toggleSearchBar} className="focus:outline-none" aria-label="Toggle search bar">
                        <HiOutlineSearch size={24} className="text-gray-800 dark:text-white" />
                    </button>
                </div>

                {/* Toggleable search bar for small screens */}
                {searchVisible && (
                    <div className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 w-full max-w-md px-4 transition-all duration-300 ease-in-out z-50 lg:hidden">
                        <InputText
                            placeholder="Search..."
                            className="w-full bg-gray-50 dark:bg-gray-800 text-gray-800 dark:text-white rounded-lg p-2 "
                        />
                    </div>
                )}

                {/* User Avatar with Dropdown */}
                <div className="relative">
                    <button
                        onClick={toggleDropdown}
                        className="h-8 w-8 bg-gradient-to-r from-purple-500 to-blue-500 rounded-full flex items-center justify-center text-white font-bold focus:outline-none"
                        aria-label="User avatar dropdown"
                    >
                        L
                    </button>

                    {dropdownVisible && (
                        <div className="absolute right-0 mt-2 bg-white dark:bg-gray-800 text-gray-800 dark:text-white rounded-lg shadow-lg w-48 z-50 text-left">
                            <ul className="py-2">
                                <li>
                                    <a
                                        href="#"
                                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700"
                                    >
                                        View Profile
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700"
                                    >
                                        Help Center
                                    </a>
                                </li>
                                <li>
                                    <button onClick={handleLogout} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 text-red-400">
                                        Logout
                                    </button>
                                </li>
                            </ul>
                        </div>
                    )}
                </div>

                {/* Theme Switcher */}
                <ThemeSwitcher />
            </div>
        </header>
    );
};

export default Dheader;
