

import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Link } from 'react-router-dom';
import authApi from '../App/API/auth';

const Signup = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [collegeID, setcollegeID] = useState('');
    const [collegePhoto, setCollegePhoto] = useState(null);
    const [agreed, setAgreed] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleSignup = async () => {
        if (!username || !email || !password || !collegeID || !collegePhoto) {
            setErrorMessage('Please fill in all fields and agree to the terms.');
            return;
        }

        setLoading(true);
        const userData = {
            username,
            email,
            password,
            collegeID,
            profileImage: collegePhoto,
        };

        try {
            const response = await authApi.registerUser(userData);

            if (response.success) {
                console.log('Signup successful:', response);
                // Optionally redirect to another page or show a success message
            } else {
                setErrorMessage(response.error || 'Something went wrong, please try again.');
            }
        } catch (error) {
            console.error('Error during signup:', error);
            setErrorMessage('An error occurred. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex overflow-hidden flex-col md:flex-row items-center justify-center dark:bg-slate-950">
            <div className="flex flex-col justify-center p-9 w-full md:w-1/2 lg:w-1/3 bg-white dark:bg-slate-950">
                <h2 className="mb-5 text-2xl sm:text-3xl md:text-4xl font-bold uppercase tracking-normal bg-clip-text text-transparent bg-gradient-to-r from-purple-700 to-cyan-500 text-center">
                    Create your free account
                </h2>
                <p className="mb-4 text-1xl text-gray-700 dark:text-gray-300 text-center">
                    Join our collegemate community to connect, share experiences, and access exclusive resources!
                </p>

                {errorMessage && <div className="text-red-500 text-center mb-4">{errorMessage}</div>}

                <form className="space-y-6">
                    {/* Username Field */}
                    <div className="space-y-5">
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <i className="pi pi-user text-gray-400"></i>
                            </div>
                            <InputText
                                id="username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                placeholder="Enter your username"
                                className="p-inputtext w-full pl-10 pr-4 text-black placeholder-gray-500 transition-all duration-200 border border-purple-300 rounded-md bg-gray-50 focus:border-purple-600 focus:bg-white"
                                required
                            />
                        </div>
                    </div>

                    {/* College Name Field */}
                    <div className="space-y-5">
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <i className="pi pi-id-card text-gray-400"></i>
                            </div>
                            <InputText
                                id="collegeName"
                                value={collegeID}
                                onChange={(e) => setcollegeID(e.target.value)}
                                placeholder="Enter your college name"
                                className="p-inputtext w-full pl-10 pr-4 text-black placeholder-gray-500 transition-all duration-200 border border-purple-300 rounded-md bg-gray-50 focus:border-purple-600 focus:bg-white"
                                required
                            />
                        </div>
                    </div>

                    {/* Email Field */}
                    <div className="space-y-5">
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <i className="pi pi-envelope text-gray-400"></i>
                            </div>
                            <InputText
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Enter your email"
                                className="p-inputtext w-full pl-10 pr-4 text-black placeholder-gray-500 transition-all duration-200 border border-purple-300 rounded-md bg-gray-50 focus:border-purple-600 focus:bg-white"
                                required
                            />
                        </div>
                    </div>

                    {/* Password Field */}
                    <div className="space-y-5">
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <i className="pi pi-lock text-gray-400"></i>
                            </div>
                            <InputText
                                id="password"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Enter your password"
                                className="p-inputtext w-full pl-10 pr-4 text-black placeholder-gray-500 transition-all duration-200 border border-purple-300 rounded-md bg-gray-50 focus:border-purple-600 focus:bg-white"
                                required
                            />
                        </div>
                    </div>

                    {/* College Photo Upload */}
                    <div className="space-y-5">
                        <div className="relative">
                            <input
                                id="collegePhoto"
                                type="file"
                                onChange={(e) => setCollegePhoto(e.target.files[0])}
                                className="w-full p-2 text-gray-900  dark:text-gray-500 bg-gray-50 dark:bg-gray-900 border border-purple-300 rounded-md focus:border-purple-600 focus:bg-white"
                                required
                            />
                        </div>
                    </div>

                    {/* Terms and Conditions Checkbox */}
                    <div className="space-y-5">
                        <div className="flex items-center">
                            <Checkbox
                                inputId="agreed"
                                checked={agreed}
                                onChange={(e) => setAgreed(e.checked)}
                            />
                            <label htmlFor="agreed" className="ml-2 text-gray-700 dark:text-gray-300">
                                I agree to the <Link to="/terms" className="text-purple-600">terms and conditions</Link>
                            </label>
                        </div>
                    </div>

                    {/* Signup Button */}
                    <Button
                        label={loading ? 'Signing Up...' : 'Sign Up'}
                        onClick={handleSignup}
                        className="w-full p-3 text-white bg-gradient-to-r from-purple-700 to-cyan-500 hover:bg-gradient-to-l rounded-md"
                        disabled={loading}
                    />
                </form>

                <p className="mt-6 text-center text-gray-700 dark:text-gray-300">
                    Already have an account? <Link to="/login" className="text-purple-600">Login here</Link>
                </p>
            </div>
        </div>
    );
};

export default Signup;
