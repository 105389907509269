import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { useNavigate } from 'react-router-dom';
import authApi from '../App/API/auth';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent form reload
        setError(''); // Reset error state before submission

        try {
            const NotificationToken = "ExponentPushToken[FtzkIkHaWgFz5VSMXRNAwm]"; // Replace with actual logic if needed
            const response = await authApi.login(email, password, NotificationToken);

            if (response && response.token) {
                console.log('Login successful:', response);
                // Save the token in localStorage
                localStorage.setItem('authToken', response.token);
                // Redirect to dashboard
                navigate('/dashboard');
            } else {
                setError('Invalid credentials. Please try again.');
            }
        } catch (err) {
            console.error('Login error:', err.message);
            setError(err.message || 'An error occurred. Please try again.');
        }
    };

    return (
        <div className="flex min-h-screen items-center justify-center px-4 bg-white dark:bg-slate-950">
            <div className="flex flex-col justify-center p-6 w-full md:w-1/2 lg:w-1/3 bg-white dark:bg-slate-950 rounded-lg border-purple-200 dark:border-cyan-500">
                <h2 className="mb-5 text-2xl sm:text-3xl md:text-4xl font-bold uppercase tracking-normal bg-clip-text text-transparent bg-gradient-to-r from-purple-700 to-cyan-500 text-center">
                    Sign in to your account
                </h2>
                <p className="mb-4 text-gray-600 dark:text-gray-300 text-center">
                    Don't have an account yet?{' '}
                    <a href="/signup" className="text-purple-700 font-semibold hover:underline">
                        Create now
                    </a>
                </p>
                <form className="space-y-6" onSubmit={handleSubmit}>
                    <div className="space-y-5">
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <i className="pi pi-envelope text-gray-400"></i>
                            </div>
                            <InputText
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Enter email"
                                className="p-inputtext w-full pl-10 pr-4 text-black placeholder-gray-500 border rounded-md focus:outline-none focus:border-cyan-500"
                                required
                            />
                        </div>
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <i className="pi pi-lock text-gray-400"></i>
                            </div>
                            <InputText
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Enter password"
                                className="p-inputtext w-full pl-10 pr-10 text-black placeholder-gray-500 border rounded-md focus:outline-none focus:border-cyan-500"
                                required
                            />
                            <div
                                className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                <i className={`pi ${showPassword ? 'pi-eye-slash' : 'pi-eye'} text-gray-400`}></i>
                            </div>
                        </div>
                    </div>
                    {error && <p className="text-red-500 text-center">{error}</p>}
                    <div className="relative inline-flex items-center justify-center gap-3 group mt-10 w-full max-w-md">
                        <div className="absolute inset-0 duration-1000 opacity-60 transition-all bg-gradient-to-r from-indigo-500 via-pink-500 to-yellow-400 rounded-full blur-lg filter group-hover:opacity-100 group-hover:duration-200"></div>
                        <button
                            type="submit"
                            className="relative flex items-center justify-center text-lg rounded-full bg-slate-950 px-8 py-2 font-semibold text-white transition-all duration-200 hover:bg-slate-950 hover:shadow-lg hover:-translate-y-0.5 hover:shadow-gray-600/30 w-full"
                        >
                            Sign In
                        </button>
                    </div>
                </form>
                <a href="/" className="mt-6 text-center text-purple-700 hover:underline">
                    Back to homepage
                </a>
            </div>
        </div>
    );
};

export default Login;
