// import React from 'react';
// import { useSelector } from 'react-redux';
// import { Navigate } from 'react-router-dom';

// const ProtectedRoute = ({ children }) => {
// //   const { isAuthenticated } = useSelector((state) => state.auth);
// const { isAuthenticated } = useSelector((state) => state.auth)
//   return isAuthenticated ? children : <Navigate to="/login" />;
// };

// export default ProtectedRoute;
import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = localStorage.getItem('authToken'); // Checking if the token exists in localStorage

  return isAuthenticated ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;
