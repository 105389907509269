import React from 'react';
import { BarChart, BookOpen, Award, BookCheck } from 'lucide-react';

const StudentProfile = () => {
    // const courses = [
    //     {
    //         title: "Advanced JavaScript Techniques",
    //         author: "Jane Howard",
    //         rating: 4.5,
    //         purchaseDate: "10 Mar 2024",
    //         validity: "10 Mar 2024",
    //         price: "$09",
    //         progress: 75,
    //         type: "Paid"
    //     },
    //     {
    //         title: "Python for Data Science",
    //         author: "John Doe",
    //         rating: 4.7,
    //         purchaseDate: "15 Apr 2024",
    //         validity: "15 Apr 2024",
    //         price: "$19",
    //         progress: 85,
    //         type: "Paid"
    //     },
    //     {
    //         title: "Introduction to Machine Learning",
    //         author: "Emily Clark",
    //         rating: 4.8,
    //         purchaseDate: "20 May 2024",
    //         validity: "20 May 2024",
    //         price: "$49",
    //         progress: 90,
    //         type: "Paid"
    //     }
    // ];

    return (
        <>
            <div className="mb-6">

                <div className="flex items-center text-sm text-gray-500">
                    <span className="hover:text-blue-600 cursor-pointer">Home</span>
                    <span className="mx-2">›</span>
                    <span className=' text-purple-600'>Student Profile</span>
                </div>
            </div>
            <div className="min-h-screen bg-gray-100 dark:bg-slate-950 text-slate-900 dark:text-white p-4 md:p-6 lg:p-8">
                {/* Profile Header */}
                <div className="mb-8 bg-gray-100 dark:bg-slate-950 text-slate-900 dark:text-white rounded-lg shadow-md overflow-hidden">
                    <div className="relative h-48 bg-gradient-to-r from-blue-500 to-purple-600">
                        <div className="absolute -bottom-16 left-8 flex items-end space-x-4">
                            <div className="w-32 h-32 rounded-full border-2 border-white bg-white shadow-lg overflow-hidden">
                                <img
                                    src="https://images.pexels.com/photos/2010877/pexels-photo-2010877.jpeg?auto=compress&cs=tinysrgb&w=400"
                                    alt="Profile"
                                    className="w-full h-full object-cover"
                                />
                            </div>
                            <div className="mt-6 text-slate-900 dark:text-white">
                                <h1 className="text-2xl font-bold">Tanisha</h1>
                                <p className="text-gray-800 dark:text-gray-700">UI/IX - Student at Academine</p>
                            </div>
                        </div>
                    </div>

                    <div className="mt-20 p-6">
                        {/* Stats Grid */}
                        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-8">
                            <div className="bg-blue-50 dark:bg-slate-900 text-slate-900 dark:text-white p-4 rounded-lg">
                                <div className="flex items-center space-x-2">
                                    <BookOpen className="text-blue-600" />
                                    <span className="text-gray-600">Courses in Progress</span>
                                </div>
                                <p className="text-3xl font-bold text-blue-600 mt-2">09</p>
                            </div>
                            <div className="bg-green-50 dark:bg-slate-900 text-slate-900 dark:text-white p-4 rounded-lg">
                                <div className="flex items-center space-x-2">
                                    <BookCheck className="text-green-600" />
                                    <span className="text-gray-600">Completed</span>
                                </div>
                                <p className="text-3xl font-bold text-green-600 mt-2">07</p>
                            </div>
                            <div className="bg-purple-50 dark:bg-slate-900 text-slate-900 dark:text-white p-4 rounded-lg">
                                <div className="flex items-center space-x-2">
                                    <BarChart className="text-purple-600" />
                                    <span className="text-gray-600">Purchased</span>
                                </div>
                                <p className="text-3xl font-bold text-purple-600 mt-2">11</p>
                            </div>
                            <div className="bg-yellow-50 dark:bg-slate-900 text-slate-900 dark:text-white p-4 rounded-lg">
                                <div className="flex items-center space-x-2">
                                    <Award className="text-yellow-600" />
                                    <span className="text-gray-600">Certificates</span>
                                </div>
                                <p className="text-3xl font-bold text-yellow-600 mt-2">03</p>
                            </div>
                        </div>

                        {/* About Section */}
                        <div className="mb-8">
                            <h2 className="text-xl font-semibold mb-4 text-left">About</h2>
                            <div className="space-y-2 text-gray-600">
                                <p className="flex items-center space-x-2">
                                    <span>📍</span>
                                    <span>Lives in 1901 Thornridge Ave, New York, NY</span>
                                </p>
                                <p className="flex items-center space-x-2">
                                    <span>💼</span>
                                    <span>Works at eBay as a Senior Software Engineer</span>
                                </p>
                                <p className="flex items-center space-x-2">
                                    <span>🎓</span>
                                    <span>Studied Computer Science at Stanford University</span>
                                </p>
                                <p className="flex items-center space-x-2">
                                    <span>📅</span>
                                    <span>Joined on January 2020</span>
                                </p>
                                <p className="flex items-center space-x-2">
                                    <span>📧</span>
                                    <span>Email: tanisha@example.com</span>
                                </p>
                                <p className="flex items-center space-x-2">
                                    <span>🔗</span>
                                    <span>Website: <a href="https://johndoeportfolio.com" className="text-blue-600 hover:underline">johndoeportfolio.com</a></span>
                                </p>
                                <p className="flex items-center space-x-2">
                                    <span>🌐</span>
                                    <span>Speaks English, Spanish</span>
                                </p>
                                <p className="flex items-center space-x-2">
                                    <span>🏆</span>
                                    <span>Achievements: Awarded Employee of the Year 2021</span>
                                </p>
                            </div>
                        </div>

                    </div>
                </div>

                {/* Courses Section */}
                {/* <div className="bg-gray-100 dark:bg-slate-900 text-slate-900 dark:text-white rounded-lg shadow-md p-6">
                    <div className="flex justify-between items-center mb-6">
                        <h2 className="text-xl font-semibold">My Courses</h2>
                        <div className="text-sm text-gray-500">Showing 3 of 20 entries</div>
                    </div>

                    <div className="space-y-6">
                        {courses.map((course, index) => (
                            <div key={index} className="border rounded-lg p-4 hover:shadow-md transition-shadow">
                                <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
                                    <div className="w-full md:w-2/3">
                                        <div className="flex items-start space-x-4">
                                            <img
                                                src="https://images.pexels.com/photos/11035380/pexels-photo-11035380.jpeg?auto=compress&cs=tinysrgb&w=400"
                                                alt={course.title}
                                                className="w-20 h-20 rounded-lg object-cover"
                                            />
                                            <div>
                                                <h3 className="font-semibold text-lg">{course.title}</h3>
                                                <div className="flex items-center space-x-2 text-sm text-gray-500">
                                                    <span>⭐ {course.rating}</span>
                                                    <span>•</span>
                                                    <span>Author - {course.author}</span>
                                                </div>
                                                <div className="mt-2 text-sm text-gray-600">
                                                    <span className="mr-4">Purchase: {course.purchaseDate}</span>
                                                    <span>Valid till: {course.validity}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-4 md:mt-0 w-full md:w-1/3 flex flex-col md:items-end space-y-2">
                                        <span className="text-lg font-semibold">{course.price}</span>
                                        <div className="w-full md:w-32 bg-gray-200 rounded-full h-2">
                                            <div
                                                className="bg-purple-600 h-2 rounded-full"
                                                style={{ width: `${course.progress}%` }}
                                            ></div>
                                        </div>
                                        <span className="text-sm text-gray-500">{course.progress}% Complete</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="mt-6 flex justify-center space-x-2">
                        <button className="px-3 py-1 border rounded bg-gray-100 text-slate-900">1</button>
                        <button className="px-3 py-1 border rounded hover:bg-slate-800">2</button>
                        <button className="px-3 py-1 border rounded hover:bg-slate-800">3</button>
                        <button className="px-3 py-1 border rounded hover:bg-slate-800">→</button>
                    </div>
                </div>
                 */}
            </div>
        </>
    );
};

export default StudentProfile;