import axiosInstance from "./axios";

const authApi = {
  registerUser: async (userData) => {
    try {
      if (!userData) {
        console.error('Error: userData is missing or invalid');
        return { success: false, error: 'User data is missing or invalid' };
      }
  
      const formData = new FormData();
  
      // If user has uploaded a profile image, append it
      if (userData.profileImage) {
        const file = userData.profileImage;  // Should be a file input element or URI of the file
        formData.append('image', file);
      } else {
        console.log('Images information is missing or invalid');
      }
  
      // Append the form data (username, email, password, etc.)
      formData.append('username', userData.username || '');
      formData.append('email', userData.email || '');
      formData.append('password', userData.password || '');
  
      // Append the college name (collegeID as string) and the college ObjectId (college as string)
      formData.append('collegeID', userData.collegeID || '');  // College name
      // formData.append('college', userData.college || '');  // College ObjectId (MongoDB ObjectId)
  
      console.log(userData);  // Debugging log to ensure userData is as expected
      
      // Send the data to the backend
      const response = await axiosInstance.post('/register/image', formData, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      });
  
      if (response) {
        return response.data;
      }
    } catch (error) {
      console.error('Error during registration:', error.message);
      return { success: false, error: 'An unexpected error occurred' };
    }
  },
  

 login: async (email, password, NotificationToken) => {
    try {
      const response = await axiosInstance.post('/user/login', {
        email,
        password,
        NotificationToken,
      });
console.log(response,'login1')
      if (response.status !== 200) {
        throw new Error(response.data.message || 'Login failed');
      }

      const data = response.data;

      return data;
    } catch (error) {
      console.error('Login failed:', error.message);
      throw error;
    }
  },

};

export default authApi;
